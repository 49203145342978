import React from 'react'
import { format } from 'date-fns'

interface TimestampProps {
	timestamp: number // Unix timestamp in seconds
}

const TimestampDisplay: React.FC<TimestampProps> = ({ timestamp }) => {
	const date = new Date(timestamp * 1000) // Convert to milliseconds
	const formattedDate = format(date, 'dd/MM/yyyy HH:mm')
	return <div>{formattedDate}</div>
}

export default TimestampDisplay
