import React, { useEffect, useState, useCallback } from 'react'
import CustomStore from 'devextreme/data/custom_store'
import List from 'devextreme-react/list'
import Button from 'devextreme-react/button'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import axios from 'axios'
import { loadDataSearch } from '../../utils/model-data'
import notify from 'devextreme/ui/notify'

export default function LinkPermit(props: any) {
	const [dataSource, setDataSource] = useState<any>()
	const { args, filter, totalCount } = props
	const [selectedItemKey, setSelectedItemKey] = useState<any>('')
	const [loading, setLoading] = useState(false)
	const { model, folder, insert, key, searchExpr, extension } = args

	const handleSelectedItemKeysChange = useCallback((e: any) => {
		const { name, value } = e
		if (name === 'selectedItemKeys') {
			setSelectedItemKey(value)
		}
	}, [])

	useEffect(() => {
		const ds = new CustomStore({
			key: key,
			load: (loadOptions: any) => {
				return loadDataSearch(loadOptions, {
					folder,
					model,
					sort: `[{"selector":"${searchExpr}","desc":false}]`,
				})
			},
		})
		setDataSource(ds as any)
	}, [folder, model, searchExpr, key])

	function ItemTemplate(data: any) {
		return (
			<div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
				<p>{data[searchExpr]}</p>
				<p className="extension">
					{data[extension] === 2 ? 'extension' : data[extension] === 1 ? null : data[extension]}
				</p>
			</div>
		)
	}

	const handleUpdatePermits = () => {
		setLoading(true)
		const data = {
			filter: filter,
			id: selectedItemKey,
			model: model,
			folder: folder,
			insert: insert,
		}

		axios
			.post(`${API_FIBER_URL}/popup/insert`, data)
			.then(response => {
				setLoading(false)
				if (response.status === 200) {
					const existingRowsCount = response.data.existingRowsCount
					const permitsLinked = totalCount - existingRowsCount
					if (permitsLinked < 1) {
						notify(
							`Permits not inserted, because are already linked with selected PermitID."`,
							'warning',
							4000
						)
					} else {
						const message =
							existingRowsCount > 0
								? `Permits Inserted successful: ${permitsLinked} of ${totalCount} linked with selected PermitID.`
								: `Permits Inserted successful: ${totalCount} linked with selected PermitID.`
						notify(message, 'success', 4000)
					}
				}
			})
			.catch(error => {
				setLoading(false)
				console.error(error)
				notify(`Error inserting permits`, 'error', 2500)
			})
	}

	return (
		<>
			<div className="widget-container">
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<p style={{ margin: '0 0 10px 0', fontWeight: 'bold', fontSize: 20, textAlign: 'center' }}>
						Select Permit ID
					</p>
					{loading && (
						<div>
							<span>Wait until permits linked... </span>
							<LoadIndicator id="small-indicator" height={20} width={20} />
						</div>
					)}
				</div>
				<List
					dataSource={dataSource}
					height={'54vh'}
					selectionMode="single"
					itemRender={ItemTemplate}
					searchExpr={searchExpr}
					searchEnabled={true}
					searchMode="contains"
					selectedItemKeys={selectedItemKey}
					showSelectionControls={true}
					selectByClick={true}
					pageLoadMode="scrollBottom"
					onOptionChanged={handleSelectedItemKeysChange}
				/>
			</div>
			<br />
			<div className="widget-container">
				{totalCount && totalCount < 100 ? (
					<div className="popup-message">
						<p>{`You are going to insert ${totalCount} rows with the selected Permit ID.`}</p>
						<Button text="Insert" icon="check" disabled={!selectedItemKey} onClick={handleUpdatePermits} />
					</div>
				) : (
					<div className="popup-message">
						<i style={{ color: '#FF794E', fontSize: '24px' }} className="dx-icon-warning"></i>
						<p
							style={{ color: '#FF794E' }}
						>{`To link permits, please ensure your filter results in a maximum of 100 rows.`}</p>
					</div>
				)}
			</div>
		</>
	)
}
