import React, { useEffect } from 'react'
import LoadPanel from 'devextreme-react/load-panel'
import { redirectToArtemisID, clearAccessToken } from '../../api/auth'

export default function LoginFlow() {
	useEffect(() => {
		clearAccessToken()
		redirectToArtemisID()
	}, [])

	return <LoadPanel visible={true} />
}
