import { custom } from 'devextreme/ui/dialog'

export const confirmDialog = async (title: string, message: string) => {
	const dialog = custom({
		title: title,
		messageHtml: message,
		buttons: [
			{
				text: 'Yes',
				onClick: e => ({ buttonText: 'Yes', clicked: true }),
			},
			{
				text: 'No',
				onClick: e => ({ buttonText: 'No', clicked: false }),
			},
		],
	})
	return (await dialog.show()).clicked
}
