import React, { useEffect } from 'react'
import LoadPanel from 'devextreme-react/load-panel'
import { useAuth } from '../../contexts/auth'
import { useParams } from 'react-router-dom'

export default function AuthFlow() {
	const { signIn } = useAuth()
	const routeParams = useParams()
	const { code } = routeParams

	useEffect(() => {
		signIn(code ?? '')
		return () => {}
	}, [code, signIn])

	return <LoadPanel visible={true} />
}
