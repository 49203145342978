import axios from 'axios'
import type { User } from '../types'
import * as Sentry from '@sentry/react'
import { socketConnect } from '../socket'

axios.interceptors.response.use(
	response => {
		return response
	},
	err => {
		return new Promise((resolve, reject) => {
			if (err.response) {
				switch (err.response.status) {
					case 400:
						throw err
					case 401:
						clearAccessToken()
						redirectToArtemisID()
						break
					default:
						Sentry.captureException(err)
						if (process.env.REACT_APP_SENTRY_ENV === 'live') {
							err.response.data.message = 'Server error'
						}
						throw err
				}
			}
		})
	}
)

export async function signIn(code: string): Promise<{ isOk: boolean; data?: User; message?: string }> {
	return new Promise((resolve, reject) => {
		axios
			.get(`${API_ID_URL}/auth/token/${code}`)
			.then(response => {
				setAccessToken(response.data.auth.access_token)
				socketConnect()
				resolve(getUser())
			})
			.catch(error => {
				redirectToArtemisID()
				reject({
					isOk: false,
				})
			})
	})
}

export function redirectToArtemisID() {
	window.location.href = `${ID_URL}/identifier/fiber`
}

export function setAccessToken(access_token: string) {
	localStorage.setItem('jwt_access_token', access_token)
	axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
}

export function clearAccessToken() {
	localStorage.removeItem('jwt_access_token')
	delete axios.defaults.headers.common.Authorization
}

export async function getUser(): Promise<{
	isOk: boolean
	data?: User
	message?: string
}> {
	const access_token = localStorage.getItem('jwt_access_token')
	if (access_token) {
		socketConnect()
		axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
		return new Promise((resolve, reject) => {
			axios
				.get(`${API_ID_URL}/auth/me?full=1&tracking=1`)
				.then(response => {
					const { user } = response.data
					const apps = Object.values(user.applications)
					if (apps.indexOf('Legacy') > -1 || apps.indexOf('Fiber') > -1) {
						resolve({
							isOk: true,
							data: {
								email: user.Username.split('@')[0],
								apps: apps,
							},
						})
						Sentry.setUser({
							id: user.UserID,
							email: user.Username,
						})
					} else {
						redirectToArtemisID()
						resolve({
							isOk: false,
						})
					}
				})
				.catch(error => {
					redirectToArtemisID()
					reject({
						isOk: false,
					})
				})
		})
	} else {
		return {
			isOk: false,
		}
	}
}
