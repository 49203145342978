import React from 'react'
import TagBox from 'devextreme-react/tag-box'

interface MultipleSelectProps {
	data: any
}

export const MultipleSelect: React.FC<MultipleSelectProps> = ({ data }) => {
	const lookup = data.column.lookup
	return (
		<TagBox
			dataSource={lookup.dataSource}
			defaultValue={data.value}
			valueExpr={lookup.valueExpr}
			displayExpr={lookup.displayExpr}
			hideSelectedItems={true}
			onValueChanged={(e: any) => {
				data.setValue(e.value)
			}}
		/>
	)
}

export const cellMultipleSelect = (container: any, options: any) => {
	const text = options.value
		? options.column.lookup.dataSource
				.filter((item: any) => options.value.includes(item.value))
				.map((item: any) => item.text)
				.join(', ')
		: ''

	container.textContent = text
}
