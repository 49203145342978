import React, { useState } from 'react'
import FileUploader from 'devextreme-react/file-uploader'
import Button from 'devextreme-react/button'
import mime from 'mime'

const acceptTypesByExtention = (extensions: string[]) => {
	return extensions.map(ext => mime.getType(ext)).join(', ')
}

const access_token = localStorage.getItem('jwt_access_token')

export default function UploadField(props: any) {
	const { folder, model, name, value, setValue, settings } = props
	const { extensions, required } = settings

	const [labelText, setLabelText] = useState(value || '')

	const minifiyUrl = (url: string) => {
		let dispValue = url
		if (dispValue && dispValue.length > 30) {
			dispValue = `${dispValue.substr(0, 20)}...${dispValue.substr(-10)}`
		}
		return dispValue
	}

	return (
		<div style={{ position: 'relative' }}>
			<FileUploader
				selectButtonText="SELECT"
				labelText={minifiyUrl(labelText)}
				uploadMode="instantly"
				uploadUrl={`${API_FIBER_URL}/view/upload`}
				uploadHeaders={{
					authorization: `Bearer ${access_token}`,
				}}
				uploadCustomData={{ folder, model, field: name }}
				onUploadStarted={() => {}}
				onUploaded={(e: any) => {
					const data = JSON.parse(e.request.response)
					setLabelText(data.filename)
					setValue(data.filename)
				}}
				{...(extensions &&
					extensions.length > 0 && {
						allowedFileExtensions: extensions,
						accept: acceptTypesByExtention(extensions),
					})}
			/>
			{!required && labelText && (
				<Button
					style={{ position: 'absolute', right: '0px', top: '0px', transform: 'scale(0.5)' }}
					icon="clear"
					hint="Unlink file"
					disabled={false}
					onClick={async () => {
						setLabelText('')
						setValue('')
					}}
				/>
			)}
		</div>
	)
}
