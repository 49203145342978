import socketIO from 'socket.io-client'
import axios from 'axios'

let _socket = null
let _socket_auth = false

export const socketGet = () => {
	return _socket
}

export const socketConnect = () => {
	const token = localStorage.getItem('jwt_access_token')
	if (_socket) {
		if (_socket_auth) {
			return
		}
		_socket.disconnect()
	}
	console.info('Socket token:', token)
	if (token) {
		// console.info('Socket connect with token :)')
		_socket = socketIO.connect(`${SOCKET_URL}`, { query: `auth_token=${token}` })
		_socket.on('connect', function () {
			console.info('Socket connect with token :)', _socket.id)
			axios.defaults.headers.common['X-Socket-Id'] = _socket.id
		})
		_socket_auth = true
		return
	}
	console.info('Socket connect without token :(')
	_socket = socketIO.connect(`${SOCKET_URL}`)
	_socket_auth = false
}
