import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react'
import TreeView from 'devextreme-react/tree-view'
import { useNavigation } from '../../contexts/navigation'
import Button from 'devextreme-react/button'
import './SideNavigationMenu.scss'
import type { SideNavigationMenuProps, NavigationItem } from '../../types'
import { socketGet } from '../../socket'
import { useAuth } from '../../contexts/auth'
import UserPanel from '../user-panel/UserPanel'
import * as Sentry from '@sentry/react'
import axios from 'axios'

import * as events from 'devextreme/events'
import { Link } from 'react-router-dom'

export default function SideNavigationMenu(props: React.PropsWithChildren<SideNavigationMenuProps>) {
	const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } = props

	const [color, setColor] = useState('')
	const [resetMemo, setResetMemo] = useState(false)
	const [navigationItems, setNavigationItems] = useState<string[]>([])
	const [processes, setProcesses] = useState(false)
	const [reports, setReports] = useState<string[]>([])

	const socket = socketGet()
	const { user } = useAuth()

	useEffect(() => {
		const handlerFinished = () => {
			setColor('green')
		}
		const handlerWarning = () => {
			setColor('orange')
		}
		const handlerError = () => {
			setColor('red')
		}
		const handlerSentryError = (payload: any) => {
			const { event_id } = payload
			Sentry.setTag('backend.event', event_id)
		}

		if (socket) {
			socket.on('legacy_export_finished', handlerFinished)
			socket.on('legacy_export_warning', handlerWarning)
			socket.on('legacy_export_error', handlerError)
			socket.on('sentry_error', handlerSentryError)

			return () => {
				socket.off('legacy_export_finished', handlerFinished)
				socket.off('legacy_export_warning', handlerWarning)
				socket.off('legacy_export_error', handlerError)
				socket.off('sentry_error', handlerSentryError)
			}
		}
	}, [socket])

	useEffect(() => {
		axios
			.get(`${API_FIBER_URL}/views`)
			.then(response => response.data)
			.then(data => {
				setNavigationItems(data.views)
				setProcesses(data.processes)
				setReports(data.reports)
				setResetMemo(true)
			})
	}, [])

	const topLevelItems: { [segment: string]: NavigationItem } = {}

	navigationItems.forEach(folder => {
		const segments = folder.split('/')
		let currentPath = 'v'
		let currentItem: { [segment: string]: NavigationItem } | NavigationItem[] = topLevelItems

		segments.forEach((segment, index) => {
			currentPath += `/${segment}`

			if (Array.isArray(currentItem)) {
				const existingItem = currentItem.find(item => item.text === segment)
				if (!existingItem) {
					const newItem = {
						text: segment,
						path: currentPath,
						items: [],
					}
					currentItem.push(newItem)
					currentItem = newItem.items
				} else {
					currentItem = existingItem.items
				}
			} else {
				if (!currentItem[segment]) {
					currentItem[segment] = {
						text: segment,
						path: currentPath,
						icon: 'folder',
						items: [],
					}

					if (index === 0) {
						topLevelItems[segment] = currentItem[segment]
					}
				}

				currentItem = currentItem[segment].items
			}
		})
	})

	const items: NavigationItem[] = Object.values(topLevelItems)

	if (processes) {
		items.push({
			text: 'Processes',
			path: `processes`,
			icon: 'upload',
			items: [],
		})
	}

	if (items.length > 0) {
		items.push({
			text: 'Exports',
			path: `exports`,
			icon: 'download',
			newTab: true,
			color: color,
			items: [],
		})
	}
	if (reports.length > 0) {
		items.push({
			text: 'Reports',
			path: `reports`,
			icon: 'chart',
			items: reports.map(report => ({
				text: report,
				path: `reports/${report}`,
				items: [],
			})),
		})
	}

	if (user!.apps.indexOf('ArtemisID') > -1) {
		items.push({
			text: 'Roles',
			path: `roles`,
			icon: 'group',
			items: [],
		})
	}

	const itemsMemo = useMemo(() => {
		setResetMemo(false)
		return items
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resetMemo, color])

	const {
		navigationData: { currentPath },
	} = useNavigation()

	const treeViewRef = useRef<TreeView>(null)
	const wrapperRef = useRef<HTMLDivElement>()
	const getWrapperRef = useCallback(
		(element: HTMLDivElement) => {
			const prevElement = wrapperRef.current
			if (prevElement) {
				events.off(prevElement, 'dxclick')
			}

			wrapperRef.current = element
			events.on(element, 'dxclick', (e: React.PointerEvent) => {
				openMenu(e)
			})
		},
		[openMenu]
	)

	useEffect(() => {
		const treeView = treeViewRef.current && treeViewRef.current.instance
		if (!treeView) {
			return
		}

		if (currentPath !== undefined) {
			treeView.selectItem(currentPath)
			treeView.expandItem(currentPath)
		}

		if (compactMode) {
			treeView.collapseAll()
		}
	}, [currentPath, compactMode])

	function itemRender(item: any) {
		const { text, icon, color, path, newTab } = item
		const itemText = (
			<>
				{icon && <i style={{ color: color }} className={`dx-icon dx-icon-${icon}`}></i>}
				{text}
			</>
		)
		const itemButton = newTab ? (
			<a href={`/${path}`} style={{ color: 'white' }} target="_blank" rel="noreferrer">
				{itemText}
			</a>
		) : (
			<Link style={{ color: 'white' }} to={`/${path}`}>
				{itemText}
			</Link>
		)

		return itemButton
	}

	return (
		<div className={'dx-swatch-additional side-navigation-menu'} ref={getWrapperRef}>
			{children}
			{items.length > 0 && (
				<div className={'menu-container'}>
					<TreeView
						ref={treeViewRef}
						items={itemsMemo}
						keyExpr={'path'}
						selectionMode={'single'}
						focusStateEnabled={false}
						expandEvent={'click'}
						onItemClick={e => {
							if (!e.itemData?.newTab) {
								selectedItemChanged(e)
							}
							setColor('')
						}}
						onContentReady={onMenuReady}
						itemRender={itemRender}
						width={'100%'}
					/>
					<div>
						<Button
							className={'userManual'}
							width={'100%'}
							height={50}
							stylingMode={'text'}
							icon={'textdocument'}
							text={'User Manual'}
							onClick={() =>
								window.open(
									'https://artemisitsgmbh-my.sharepoint.com/:p:/g/personal/kyriakos_galanis_artemis-its_gr/ETE1zZOMeGNMnVp1w5yyKG4B5cliBp3bWptlwxLZMpGOcQ?rtime=jBzehQYC3Eg',
									'_blank'
								)
							}
						></Button>
					</div>
					<div>
						<Button
							className={'user-button authorization'}
							width={'100%'}
							height={'100%'}
							stylingMode={'text'}
						>
							<UserPanel menuMode={'context'} />
						</Button>
					</div>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
				</div>
			)}
		</div>
	)
}
