import React, { createContext, useContext, useState, ReactNode } from 'react'

type SharePointContextType = {
	sharePointLogged: boolean
	setSharePointLogged: React.Dispatch<React.SetStateAction<boolean>>
}

const SharePointContext = createContext<SharePointContextType | undefined>(undefined)

export const useSharePoint = () => {
	const context = useContext(SharePointContext)
	if (context === undefined) {
		throw new Error('useSharePoint must be used within a LoggedProvider')
	}
	return context
}

export const SharePointProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [sharePointLogged, setSharePointLogged] = useState<boolean>(false)

	return (
		<SharePointContext.Provider value={{ sharePointLogged, setSharePointLogged }}>
			{children}
		</SharePointContext.Provider>
	)
}
