import { Routes, Route, Navigate } from 'react-router-dom'
import { LoginFlow, AuthFlow } from './components'

export default function UnauthenticatedContent() {
	return (
		<Routes>
			<Route path="/login" element={<LoginFlow />} />
			<Route path="/auth/:code" element={<AuthFlow />} />
			<Route path="*" element={<Navigate to={'/login'} />}></Route>
		</Routes>
	)
}
