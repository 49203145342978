import React, { useEffect, useState, useRef, useCallback } from 'react'
import Box, { Item as BoxItem } from 'devextreme-react/box'
import CustomStore from 'devextreme/data/custom_store'
import DataGrid, { Column, Selection, Editing, Paging, Toolbar, Item as ToolbarItem } from 'devextreme-react/data-grid'
import { Switch } from 'devextreme-react/switch'
import Button from 'devextreme-react/button'
import TabPanel, { Item as TabItem } from 'devextreme-react/tab-panel'
import { loadData, insertData, editData, deleteRolesData } from '../../utils/model-data'
import axios from 'axios'

export default function Model() {
	const [views, setViews] = useState<any>([])
	const [processes, setProcesses] = useState<any>([])
	const [reports, setReports] = useState<any>([])
	const [groups, setGroups] = useState<any>([])
	const [userStore, setUserStore] = useState()
	const [groupStore, setGroupStore] = useState()
	const [height, setHeight] = useState(0)
	const [group, setGroup] = useState({})
	const [user, setUser] = useState({})

	const usersGridRef = useRef<DataGrid | null>(null)
	const groupsGridRef = useRef<DataGrid | null>(null)

	useEffect(() => {
		document.title = 'Fiber :: Roles'
	}, [])

	const selectUser = useCallback((e: any) => {
		if (e.currentSelectedRowKeys[0]) {
			e.component.byKey(e.currentSelectedRowKeys[0]).done((item: any) => {
				item.row = e.currentSelectedRowKeys[0]
				setUser(item)
			})
		} else {
			setUser({})
		}
	}, [])

	const selectGroup = useCallback((e: any) => {
		if (e.currentSelectedRowKeys[0]) {
			e.component.byKey(e.currentSelectedRowKeys[0]).done((item: any) => {
				item.row = e.currentSelectedRowKeys[0]
				setGroup(item)
			})
		} else {
			setGroup({})
		}
	}, [])

	const changedRoleGroup = useCallback(
		(group: number, e: any) => {
			if (e.event) {
				e.event.stopPropagation()
				const u = user as any

				const { user_id, row } = u
				const cmd = e.value ? 'set' : 'unset'
				const uGroups =
					cmd === 'set'
						? setRoleGroup(u?.groups as [], group as never)
						: unsetRoleGroup(u?.groups as [], group as never)
				u.groups = uGroups
				setUser(prevUser => ({ ...(prevUser || {}), groups: uGroups }))
				const grid = usersGridRef.current?.instance
				if (grid) {
					grid.cellValue(row, 'groups', uGroups)
				}
				axios.patch(`${API_FIBER_URL}/users/${user_id}/${cmd}/${group}`)
			}
		},
		[user]
	)

	const changedRoleView = useCallback(
		(folder: string, model: string, e: any) => {
			if (e.event) {
				e.event.stopPropagation()
				const g = group as any

				const { id, row } = g
				const cmd = e.value ? 'set' : 'unset'
				const gViews =
					cmd === 'set'
						? setRoleView(g?.views as [], folder, model)
						: unsetRoleView(g?.views as [], folder, model)
				g.views = gViews
				setGroup(prevGroup => ({ ...(prevGroup || {}), views: gViews }))
				const grid = groupsGridRef.current?.instance
				if (grid) {
					grid.cellValue(row, 'views', gViews)
				}
				axios.patch(`${API_FIBER_URL}/roles/${id}/${cmd}?folder=${folder}&model=${model}`)
			}
		},
		[group]
	)

	const changedRoleProcess = useCallback(
		(process: string, e: any) => {
			if (e.event) {
				e.event.stopPropagation()
				const g = group as any

				const { id, row } = g
				const cmd = e.value ? 'set' : 'unset'
				const gProcesses =
					cmd === 'set'
						? setRoleProcess(g?.processes as [], process)
						: unsetRoleProcess(g?.processes as [], process)
				g.Processes = gProcesses
				setGroup(prevGroup => ({ ...(prevGroup || {}), processes: gProcesses }))
				const grid = groupsGridRef.current?.instance
				if (grid) {
					grid.cellValue(row, 'processes', gProcesses)
				}
				axios.patch(`${API_FIBER_URL}/roles/${id}/${cmd}?process=${process}`)
			}
		},
		[group]
	)

	const changedRoleReport = useCallback(
		(report: string, e: any) => {
			if (e.event) {
				e.event.stopPropagation()
				const g = group as any

				const { id, row } = g
				const cmd = e.value ? 'set' : 'unset'
				const gReports =
					cmd === 'set' ? setRoleReport(g?.reports as [], report) : unsetRoleReport(g?.reports as [], report)
				g.Reports = gReports
				setGroup(prevGroup => ({ ...(prevGroup || {}), reports: gReports }))
				const grid = groupsGridRef.current?.instance
				if (grid) {
					grid.cellValue(row, 'reports', gReports)
				}
				axios.patch(`${API_FIBER_URL}/roles/${id}/${cmd}?report=${report}`)
			}
		},
		[group]
	)

	useEffect(() => {
		const handleResize = () => {
			const viewportHeight = window.innerHeight
			setHeight(viewportHeight - 320)
		}

		handleResize()
		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	useEffect(() => {
		const ds = new CustomStore({
			key: 'user_id',
			load: loadOptions => {
				return loadData(`users`, loadOptions, {})
					.then(response => response.data)
					.then(data => {
						if (data.groups) {
							setGroups(data.groups)
						}
						return {
							data: data.users,
							totalCount: -1,
						}
					})
			},
		})
		setUserStore(ds as any)
	}, [])

	useEffect(() => {
		const ds = new CustomStore({
			key: 'id',
			load: loadOptions => {
				return loadData(`roles`, loadOptions, {})
					.then(response => response.data)
					.then(data => {
						setViews(data.views)
						setProcesses(data.processes)
						setReports(data.reports)
						return {
							data: data.groups,
							totalCount: -1,
						}
					})
			},
			insert: values => {
				return insertData(`roles`, values)
			},
			update: (key, values: any) => {
				values.key = key
				return editData(`roles`, values)
			},
			remove: key => {
				return deleteRolesData(key)
			},
		})
		setGroupStore(ds as any)
	}, [])

	function hasRoleGroup(groups: [], group_id: never) {
		return groups && groups.indexOf(group_id) > -1
	}

	function setRoleGroup(groups: [], group: never) {
		let newGroups = groups
		if (newGroups === null) {
			newGroups = []
		}
		if (newGroups && newGroups.indexOf(group) === -1) {
			newGroups.push(group)
		}
		return newGroups
	}

	function unsetRoleGroup(groups: [], group: never) {
		const index = groups ? groups.indexOf(group) : -1
		if (index > -1) {
			delete groups[index]
		}
		return groups
	}

	function Groups() {
		const u = user as any
		const { name } = u
		return (
			<div className="form">
				<div className="dx-fieldset">
					<div className="dx-fieldset-header" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
						User: {name}
						<p></p>
					</div>
					{groups.map((group: any) => (
						<div className="dx-field" key={group.id}>
							<div className="dx-field-label">{group.name}</div>
							<Switch
								value={hasRoleGroup(u?.groups as [], group.id as never)}
								onValueChanged={e => changedRoleGroup(group.id, e)}
							></Switch>
						</div>
					))}
				</div>
			</div>
		)
	}

	function hasRoleView(views: [], folder: string, model: string) {
		const key = `${folder}/${model}` as never
		return views && (views.indexOf(key) > -1 || views.indexOf(model as never) > -1)
	}

	function setRoleView(views: [], folder: string, model: string) {
		let newViews = views
		if (newViews === null) {
			newViews = []
		}
		const key = `${folder}/${model}` as never
		if (newViews && newViews.indexOf(key) === -1) {
			newViews.push(key)
		}
		return newViews
	}

	function unsetRoleView(views: [], folder: string, model: string) {
		const key = `${folder}/${model}` as never
		let index = views ? views.indexOf(key) : -1
		if (index > -1) {
			delete views[index]
		}
		// backward compatibility
		index = views ? views.indexOf(model as never) : -1
		if (index > -1) {
			delete views[index]
		}
		return views
	}

	function hasRoleProcess(processes: [], process: string) {
		return processes && processes.indexOf(process as never) > -1
	}

	function setRoleProcess(processes: [], process: string) {
		let newProcess = processes
		if (newProcess === null) {
			newProcess = []
		}
		if (newProcess && newProcess.indexOf(process as never) === -1) {
			newProcess.push(process as never)
		}
		return newProcess
	}

	function unsetRoleProcess(processes: [], process: string) {
		let index = processes ? processes.indexOf(process as never) : -1
		if (index > -1) {
			delete processes[index]
		}
		return processes
	}

	function hasRoleReport(reports: [], report: string) {
		return reports && reports.indexOf(report as never) > -1
	}

	function setRoleReport(reports: [], report: string) {
		let newReport = reports
		if (newReport === null) {
			newReport = []
		}
		if (newReport && newReport.indexOf(report as never) === -1) {
			newReport.push(report as never)
		}
		return newReport
	}

	function unsetRoleReport(reports: [], report: string) {
		let index = reports ? reports.indexOf(report as never) : -1
		if (index > -1) {
			delete reports[index]
		}
		return reports
	}

	function Roles() {
		const g = group as any
		const { name } = g
		return (
			<div className="form">
				<div className="dx-fieldset">
					<div className="dx-fieldset-header" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
						Group: {name} - Views
					</div>
					{(Object.entries(views) as [string, any[]][]).flatMap(([category, viewList]) =>
						viewList.map((view: any) => (
							<div className="dx-field" key={category + '/' + view.model}>
								<div className="dx-field-label" style={{ whiteSpace: 'normal' }}>
									{category} / {view.text}
								</div>
								<Switch
									value={hasRoleView(g?.views as [], category, view.model)}
									onValueChanged={e => changedRoleView(category, view.model, e)}
								></Switch>
							</div>
						))
					)}
				</div>
				<div className="dx-fieldset">
					<div className="dx-fieldset-header" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
						Group: {name} - Processes
					</div>
					{Object.entries(processes).map(([process, text]) => (
						<div className="dx-field" key={process}>
							<div className="dx-field-label">{text as string}</div>
							<Switch
								value={hasRoleProcess(g?.processes as [], process)}
								onValueChanged={e => changedRoleProcess(process, e)}
							></Switch>
						</div>
					))}
				</div>
				<div className="dx-fieldset">
					<div className="dx-fieldset-header" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
						Group: {name} - Reports
					</div>
					{Object.entries(reports).map(([report, text]) => (
						<div className="dx-field" key={report}>
							<div className="dx-field-label">{text as string}</div>
							<Switch
								value={hasRoleReport(g?.reports as [], report)}
								onValueChanged={e => changedRoleReport(report, e)}
							></Switch>
						</div>
					))}
				</div>
			</div>
		)
	}

	function deselect() {
		usersGridRef.current?.instance.deselectAll()
		groupsGridRef.current?.instance.deselectAll()
	}

	return (
		<React.Fragment>
			<h2 className={'content-block'}>Roles</h2>
			<TabPanel onTitleClick={() => deselect()}>
				<TabItem title="Users" icon="user">
					<Box direction="row" width="100%" height="100%">
						<BoxItem ratio={1}>
							<DataGrid
								ref={usersGridRef}
								height={height}
								className={'dx-card wide-card'}
								dataSource={userStore}
								remoteOperations={true}
								focusedRowEnabled={false}
								autoNavigateToFocusedRow={false}
								hoverStateEnabled={true}
								columnAutoWidth={true}
								showBorders={false}
								onSelectionChanged={selectUser}
							>
								<Paging enabled={false} />
								<Selection mode="single" />
								<Column dataField="name" caption="User" allowSorting={false} />
								<Toolbar>
									<ToolbarItem location="after">
										<Button
											icon="refresh"
											onClick={() => {
												if (usersGridRef.current && usersGridRef.current.instance) {
													usersGridRef.current.instance.refresh()
												}
											}}
										/>
									</ToolbarItem>
								</Toolbar>
							</DataGrid>
						</BoxItem>
						<BoxItem ratio={2}>
							<div className="box-item orange">{Object.keys(user).length > 0 && <Groups />}</div>
						</BoxItem>
					</Box>
				</TabItem>
				<TabItem title="Groups" icon="group">
					<Box direction="row" width="100%" height="100%">
						<BoxItem ratio={1}>
							<DataGrid
								ref={groupsGridRef}
								height={height}
								className={'dx-card wide-card'}
								dataSource={groupStore}
								remoteOperations={true}
								focusedRowEnabled={false}
								autoNavigateToFocusedRow={false}
								hoverStateEnabled={true}
								columnAutoWidth={true}
								showBorders={false}
								onSelectionChanged={selectGroup}
							>
								<Editing
									startEditAction="dblClick"
									mode="cell"
									allowAdding
									allowUpdating
									allowDeleting
								/>
								<Paging enabled={false} />
								<Selection mode="single" />
								<Column dataField="name" dataType="string" caption="Name" allowSorting={false}></Column>
							</DataGrid>
						</BoxItem>
						<BoxItem ratio={2}>
							<div className="box-item orange">{Object.keys(group).length > 0 && <Roles />}</div>
						</BoxItem>
					</Box>
				</TabItem>
			</TabPanel>
		</React.Fragment>
	)
}
