import React from 'react'
import { Popup } from 'devextreme-react/popup'
import { fixDataType, renderUrl, renderDate } from '../../utils/model-data'
import { useSharePoint } from '../../components'

export default function Rowdetails(props: any) {
	const { fields, urls, values, lookups, onHide } = props

	const { sharePointLogged } = useSharePoint()

	const renderValue = (field: any, value: any) => {
		const dataType = fixDataType(field.dataType, field.dataField)

		if (dataType === 'date') {
			return renderDate(value)
		}

		if (urls.indexOf(field.dataField as never) > -1) {
			return renderUrl(value, sharePointLogged)
		}

		if (lookups && lookups.hasOwnProperty(field.dataField)) {
			return lookups[field.dataField][value] ?? ''
		}

		return value
	}

	const renderContent = () => {
		return (
			<>
				{fields.map(
					(field: any, ndx: number) =>
						field.visible && (
							<div key={field.dataField}>
								<strong>{field.dataField}: </strong>
								{renderValue(field, values[field.dataField])}
							</div>
						)
				)}
			</>
		)
	}

	return (
		<Popup
			visible={true}
			showTitle={false}
			contentRender={renderContent}
			position="center"
			hideOnOutsideClick={() => {
				return true
			}}
			onHiding={onHide}
		/>
	)
}
