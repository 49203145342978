import React, { useEffect } from 'react'
import { useAuth } from '../../contexts/auth'
import { socketGet } from '../../socket'
import './SleekPlan.scss'
import axios from 'axios'

type SleekType = {
	on: (event: string, callback: () => void) => void
	[key: string]: any
}

const sleekPlanNewUpdateFound = () => {
	let el = document.getElementById('i-sl-button-counter-inner')
	if (el) {
		el.innerText = '👍'
	}
	el = document.getElementById('i-sl-button-counter')
	if (el) {
		el.className = 'show'
	}
}

const SleekPlanScript: React.FC = () => {
	const { user } = useAuth()
	const socket = socketGet()

	useEffect(() => {
		if (user) {
			axios
				.get(`${API_FIBER_URL}/sleekplan`)
				.then(response => response.data)
				.then((data: any) => {
					// Set global variables
					window.$sleek = window.$sleek || ([] as any)
					window.SLEEK_PRODUCT_ID = 672299640
					window.SLEEK_USER = { token: data.token }
					window.SLEEK_UPDATE_HASH = data.update_hash

					// Create script element
					const script = document.createElement('script')
					script.src = 'https://client.sleekplan.com/sdk/e.js'
					script.async = true
					script.onload = function () {
						if (data.update_hash !== localStorage.getItem('sleekplan_update_hash')) {
							setTimeout(function () {
								sleekPlanNewUpdateFound()
							}, 1000)
						}

						if (window.$sleek) {
							;(window.$sleek as unknown as SleekType).on('open', function () {
								const el = document.getElementById('i-sl-button-counter')
								if (el) {
									el.className = 'hide'
									localStorage.setItem('sleekplan_update_hash', window.SLEEK_UPDATE_HASH)
								}
							})
						}
					}

					// Append script to head
					if (!document.head.contains(script)) {
						document.head.appendChild(script)
					}

					// Cleanup function to remove the script when component unmounts
					return () => {
						if (document.head.contains(script)) {
							document.head.removeChild(script)
						}
						window.$sleek = undefined
						window.SLEEK_PRODUCT_ID = undefined
						window.SLEEK_USER = undefined
						window.SLEEK_UPDATE_HASH = ''
					}
				})
		}
	}, [user])

	useEffect(() => {
		const handlerSleekPlanNewUpdate = (payload: any) => {
			window.SLEEK_UPDATE_HASH = payload.update_hash
			sleekPlanNewUpdateFound()
		}

		if (socket) {
			socket.on('sleekplan_new_update', handlerSleekPlanNewUpdate)

			return () => {
				socket.off('sleekplan_new_update', handlerSleekPlanNewUpdate)
			}
		}
	}, [socket])

	return null // This component does not render anything
}

export default SleekPlanScript
