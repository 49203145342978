import axios from 'axios'

export const isNotEmpty = (value: string) => {
	return value !== undefined && value !== null && value !== ''
}

export const renderUrl = (value: string, smartPointLogged: boolean) => {
	const regex = /^(http|https):\/\/[^\s"<>]+$/i
	const isUrl = regex.test(encodeURI(value))
	if (isUrl) {
		let displayValue = value
		if (value.startsWith('https://artemisitsgmbh.sharepoint.com/sites/') && !smartPointLogged) {
			const parts = value.split('/')
			let filename = parts[parts.length - 1]
			filename = decodeURIComponent(filename)
			filename = filename.replace(/\s+/g, '_')
			filename = filename.replace(/[^a-zA-Z0-9._-]+/g, '')
			const access_token = localStorage.getItem('jwt_access_token')
			const base64 = btoa(JSON.stringify({ url: value, token: access_token }))
			value = `${API_FIBER_URL}/sharepoint/${encodeURIComponent(base64)}/${filename}`
		}
		if (displayValue && displayValue.length > 30) {
			displayValue = `${displayValue.substr(0, 20)}...${displayValue.substr(-10)}`
		}
		return (
			<a href={value} className="link" target="_blank" rel="noopener noreferrer">
				{displayValue}
			</a>
		)
	}

	return <span>{value}</span>
}

export const renderDate = (value: string): string => {
	if (value === null) {
		return ''
	}
	const date = new Date(value)
	const day = date.getDate().toString().padStart(2, '0')
	const month = (date.getMonth() + 1).toString().padStart(2, '0')
	const year = date.getFullYear()
	return `${day}/${month}/${year}`
}

function makeParams(options: any, extra: any): Record<string, any> {
	let params: Record<string, any> = {}

	;['skip', 'take', 'sort', 'filter', 'group', 'groupSummary'].forEach(i => {
		if (i in options && isNotEmpty(options[i])) {
			params[i] = JSON.stringify(options[i])
		}
	})

	Object.keys(extra).forEach(i => {
		params[i] = extra[i]
	})

	return params
}

function makeParamsSearch(options: any, extra: any): Record<string, any> {
	let params: Record<string, any> = {}

	;['skip', 'take', 'sort', 'searchExpr', 'searchOperation', 'searchValue'].forEach(function (i) {
		if (i in options && isNotEmpty(options[i])) {
			params[i] = JSON.stringify(options[i])
		}
	})

	Object.keys(extra).forEach(i => {
		params[i] = extra[i]
	})

	return params
}

export const loadData = (view: string, options: any, extra: any): Promise<any> => {
	const params = makeParams(options, extra)
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_FIBER_URL}/${view}/data`, params)
			.then(response => response.data)
			.then(data => {
				resolve({
					data: data.data,
					totalCount: -1,
				})
			})
			.catch(error => {
				reject(error.response.data.message)
			})
	})
}

export const insertData = (view: string, values: any) => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_FIBER_URL}/${view}/insert`, values)
			.then(response => response.data.data)
			.then(data => {
				resolve({ data })
			})
			.catch(error => {
				reject(error.response.data.message)
			})
	})
}

export const editData = (view: string, values: any) => {
	return new Promise((resolve, reject) => {
		axios
			.put(`${API_FIBER_URL}/${view}/update`, values)
			.then(response => response.data.data)
			.then(data => {
				resolve({ data })
			})
			.catch(error => {
				reject(error.response.data.message)
			})
	})
}

export const deleteData = (view: string, values: any): Promise<void> => {
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_FIBER_URL}/${view}/delete`, values)
			.then(response => response.data.data)
			.then(data => {
				resolve() // just resolve without sending any data
			})
			.catch(error => {
				reject(error.response.data.message)
			})
	})
}

export const deleteRolesData = (key: any): Promise<void> => {
	return new Promise((resolve, reject) => {
		axios
			.delete(`${API_FIBER_URL}/roles/${encodeURIComponent(key)}`)
			.then(response => response.data.data)
			.then(data => {
				resolve() // just resolve without sending any data
			})
			.catch(error => {
				reject(error.response.data.message)
			})
	})
}

export const loadDataSearch = (options: any, extra: any): Promise<any> => {
	const params = makeParamsSearch(options, extra)
	return new Promise((resolve, reject) => {
		axios
			.post(`${API_FIBER_URL}/popup/search`, params)
			.then(response => response.data)
			.then(data => {
				resolve({
					data: data.data,
					totalCount: -1,
				})
			})
			.catch(error => {
				reject(error.response.data.message)
			})
	})
}

export const fixDataType = (dataType: string, dataField: string) => {
	switch (dataType) {
		case 'text':
		case 'string':
		case 'varchar':
		case 'nvarchar':
		case 'uniqueidentifier':
		case 'array':
			return 'string'
		case 'int':
		case 'bigint':
		case 'decimal':
		case 'float':
		case 'real':
			return 'number'
		case 'bit':
		case 'bool':
		case 'boolean':
			return 'boolean'
		case 'date':
		case 'datetime':
		case 'datetime2':
		case 'custom_datetime':
			return 'date'
		default:
			console.error(`Error fixDataType ${dataType} for ${dataField}`)
			return 'string'
	}
}

let columnResize: {
	timer: any
	data: any
} = {
	timer: null,
	data: {},
}

export const handleOptionChange = (view: string, e: any, extra: any, callback: any) => {
	if (e.fullName === 'hoveredElement') {
		return
	}

	if (e.fullName === 'filterValue') {
		return
	}

	const name = e.fullName.split('.')
	if (name.length !== 2) {
		return
	}

	if (name[0].indexOf('[') === -1) {
		return
	}

	let pos = name[0].split('[')
	pos = parseInt(pos[1].substr(0, pos[1].length - 1))

	switch (name[1]) {
		case 'visible':
			if (e.value === false) {
				extra.source = pos
				const res = axios.put(`${API_FIBER_URL}/${view}/column/hide`, extra)
				if (callback) {
					res.then(response => response.data).then((data: any) => callback('hide', data))
				}
			}
			break
		case 'visibleIndex':
			extra.source = parseInt(e.previousValue)
			extra.dest = parseInt(e.value)
			const res = axios.put(`${API_FIBER_URL}/${view}/column/reorder`, extra)
			if (callback) {
				res.then(response => response.data).then((data: any) => callback('reorder', data))
			}
			break
		case 'width':
			columnResize.data[pos] = e.value
			clearTimeout(columnResize.timer)
			columnResize.timer = setTimeout(() => {
				extra.data = columnResize.data
				const res = axios.post(`${API_FIBER_URL}/${view}/column/resize`, extra)
				if (callback) {
					res.then(response => response.data).then((data: any) => callback('resize', data))
				}
				columnResize.data = {}
			}, 3000)
			break
	}
}

export const handleExporting = (view: string, e: any, extra: any) => {
	const filter = e.component.getCombinedFilter() || null
	const sort = e.component.getDataSource()?.loadOptions()?.sort
	let url = `${API_FIBER_URL}/${view}/export?`
	if (filter) {
		url = `${url}filter=${JSON.stringify(filter)}&`
	}
	if (sort) {
		url = `${url}sort=${JSON.stringify(sort)}&`
	}
	Object.keys(extra).forEach(i => {
		url = `${url}${i}=${extra[i]}&`
	})
	url = url.slice(0, -1)
	axios.get(url)
	e.cancel = true
}

export const lookupStore = (list: any): any[] => {
	// Function to process each item
	const processItem = (key: number | string, value: any) => {
		const keyAsString = String(key)
		const numericKey = String(parseInt(keyAsString)) === keyAsString ? parseInt(keyAsString) : key
		return { value: numericKey, text: value }
	}

	// Check if list is an array
	if (Array.isArray(list)) {
		return list.map(item => processItem(item, item)) // Pass index as key for array elements
	}
	// Check if list is an object
	else if (typeof list === 'object' && list !== null) {
		return Object.entries(list).map(([key, value]) => processItem(key, value))
	}
	// Return empty array if list is neither an array nor an object
	else {
		return []
	}
}

export const filterStore = (field: string, list: any): any[] => {
	// Function to process each item
	const processItem = (key: number | string, value: any) => {
		const text = value === null ? 'Empty' : value
		const keyAsString = String(key)
		const numericKey = String(parseInt(keyAsString)) === keyAsString ? parseInt(keyAsString) : key
		return { text, value: [[field, '=', numericKey]] }
	}

	// Check if list is an array
	if (Array.isArray(list)) {
		return list.map(item => processItem(item, item)) // Use index as key for array elements
	}
	// Check if list is an object
	else if (typeof list === 'object' && list !== null) {
		return Object.entries(list).map(([key, value]) => processItem(key, value)) // Use object key for object properties
	}
	// Return empty array if list is neither an array nor an object
	else {
		return []
	}
}
