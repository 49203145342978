import React, { useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { Button } from 'devextreme-react/button'
import axios from 'axios'

export default function Reports() {
	const routeParams = useParams()
	const folder = routeParams['*'] ?? ''

	const [navigationItems, setNavigationItems] = useState({})

	const navigate = useNavigate()
	const handleClick = (report: string) => navigate(folder ? `/report-detail?report=${report}` : `/reports/${report}`)

	useEffect(() => {
		document.title = 'Fiber :: Reports'
	}, [])

	useEffect(() => {
		axios
			.get(`${API_FIBER_URL}/reports/${folder}`)
			.then(response => response.data)
			.then(data => {
				setNavigationItems(data.reports)
			})
		if (folder) {
			document.title = `Fiber :: Reports :: ${folder}`
		}
	}, [folder])

	function Buttons() {
		return (
			<>
				<div className={'content-block'}>
					{Object.entries(navigationItems).map(([report, text]) => (
						<div key={report}>
							<Button
								width={'300px'}
								height={'70px'}
								text={`${text}`}
								onClick={() => handleClick(report)}
							/>
							<br /> <br />
						</div>
					))}
				</div>
			</>
		)
	}

	return (
		<React.Fragment>
			<h2 className={'content-block'}>
				<span className={'dx-icon-chart'} style={{ fontSize: '24px' }}></span>
				{` `}
				<Link className="link" to={`/reports`}>
					Reports
				</Link>
				{folder && <span> / {folder}</span>}
			</h2>
			{Object.keys(navigationItems).length > 0 && <Buttons />}
		</React.Fragment>
	)
}
