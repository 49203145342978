import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'devextreme-react/button'
import axios from 'axios'

export default function Process() {
	const [navigationItems, setNavigationItems] = useState({})

	const navigate = useNavigate()
	const handleClick = (process: string) => navigate(`/imports?process=${process}`)

	useEffect(() => {
		document.title = 'Fiber :: Processes'
	}, [])

	useEffect(() => {
		axios
			.get(`${API_FIBER_URL}/processes`)
			.then(response => response.data)
			.then(data => {
				setNavigationItems(data.processes)
			})
	}, [])

	function Buttons() {
		return (
			<>
				<div className={'content-block'}>
					{Object.entries(navigationItems).map(([process, text]) => (
						<div key={process}>
							<Button
								width={'300px'}
								height={'70px'}
								text={`${text}`}
								onClick={() => handleClick(process)}
							/>
							<br /> <br />
						</div>
					))}
				</div>
			</>
		)
	}

	return (
		<React.Fragment>
			<h2 className={'content-block'}>
				<span className={'dx-icon-upload'} style={{ fontSize: '24px' }}></span> Processes
			</h2>
			{Object.keys(navigationItems).length > 0 && <Buttons />}
		</React.Fragment>
	)
}
