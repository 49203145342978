import { useEffect } from 'react'

const useBeforeUnload = (shouldPrompt: boolean) => {
	useEffect(() => {
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			if (shouldPrompt) {
				event.preventDefault()
				event.returnValue = ''
			}
		}

		window.addEventListener('beforeunload', handleBeforeUnload)

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload)
		}
	}, [shouldPrompt])
}

export default useBeforeUnload
