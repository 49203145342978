import 'devextreme/dist/css/dx.common.css'
import './themes/generated/theme.base.css'
import './themes/generated/theme.additional.css'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import './dx-styles.scss'
import LoadPanel from 'devextreme-react/load-panel'
import { NavigationProvider } from './contexts/navigation'
import { AuthProvider, useAuth } from './contexts/auth'
import { useScreenSizeClass } from './utils/media-query'
import Content from './Content'
import UnauthenticatedContent from './UnauthenticatedContent'
import { SharePointProvider, SharePointLoader, SleekPlan } from './components'

function App() {
	const { user, loading } = useAuth()

	if (loading) {
		return <LoadPanel visible={true} />
	}

	if (user) {
		return <Content />
	}

	return <UnauthenticatedContent />
}

export default function Root() {
	const screenSizeClass = useScreenSizeClass()

	return (
		<Router>
			<SharePointProvider>
				<SharePointLoader />
				<AuthProvider>
					<NavigationProvider>
						<div className={`app ${screenSizeClass}`}>
							<SleekPlan />
							<App />
						</div>
					</NavigationProvider>
				</AuthProvider>
			</SharePointProvider>
		</Router>
	)
}
