import React, { useEffect, useState, useRef } from 'react'
import { socketGet } from '../../socket'
import DataGrid, { Column, HeaderFilter, Selection, Paging, Editing, Button } from 'devextreme-react/data-grid'
import { TimestampDisplay } from '../../components'
import { saveAs } from 'file-saver'
import axios from 'axios'

export default function Exports() {
	const [items, setItems] = useState([])
	const [reload, setReload] = useState(true)
	const socket = socketGet()
	const gridRef = useRef<DataGrid | null>(null)

	useEffect(() => {
		document.title = 'Fiber :: Exports'
	}, [])

	useEffect(() => {
		const handler = () => {
			setReload(true)
		}

		if (socket) {
			socket.on('legacy_export_finished', handler)
			socket.on('legacy_export_error', handler)

			return () => {
				socket.off('legacy_export_finished', handler)
				socket.off('legacy_export_error', handler)
			}
		}
	}, [socket])

	useEffect(() => {
		if (reload) {
			axios
				.get(`${API_FIBER_URL}/exports`)
				.then(response => response.data)
				.then(data => {
					setItems(data.exports)
				})
		}
		setReload(false)
	}, [reload])

	const handleClick = (e: any) => {
		const hash = e.row.data.hash
		axios({
			method: 'get',
			url: `${API_FIBER_URL}/exports/file/${encodeURIComponent(hash)}/download`,
			responseType: 'blob',
		}).then(response => {
			const { data, headers } = response
			const filename = atob(hash)
			const blob = new Blob([data], { type: headers['content-type'] })
			saveAs(blob, filename)
		})
	}

	const handleDeleted = (e: any) => {
		axios.delete(`${API_FIBER_URL}/exports/file/${e.data.hash}`)
	}

	const isReady = (e: any) => {
		return !e.row.data.disabled
	}
	return (
		<React.Fragment>
			<h2 className={'content-block'}>
				<span className={'dx-icon-download'} style={{ fontSize: '24px' }}></span> Exports
			</h2>
			<div className={'content-block'}>
				<DataGrid
					ref={gridRef}
					dataSource={items}
					rowAlternationEnabled={true}
					showBorders={true}
					width="100%"
					onRowRemoved={handleDeleted}
				>
					<Editing mode="row" confirmDelete={true} allowDeleting={true} />
					<Paging defaultPageSize={15} defaultPageIndex={0} />
					<Selection mode="single" />
					<Column dataField="file" />
					<Column
						dataField="date"
						width="150"
						cellRender={({ data }) => {
							return <TimestampDisplay timestamp={data['time']}></TimestampDisplay>
						}}
					/>
					<Column type="buttons" width="150">
						<Button name="delete" visible={isReady} />
						<Button icon="download" hint="download" onClick={handleClick} visible={isReady} />
					</Column>
				</DataGrid>
				<HeaderFilter visible={true} />
			</div>
		</React.Fragment>
	)
}
