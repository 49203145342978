import React, { useEffect, useCallback, useState, useRef } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import { LoadPanel } from 'devextreme-react/load-panel'
import { GoogleMap, Marker, useJsApiLoader, MarkerClusterer, InfoWindow, InfoBox } from '@react-google-maps/api'
import Rowdetails from '../row-deatail/RowDetail'
import './GoogleMap.scss'
import axios from 'axios'
import md5 from 'md5'
interface LatLng {
	lat: number
	lng: number
}

interface MarkerData {
	id: string
	lat: string
	lng: string
	label: string
	photo: string
}

export default function Map(props: any) {
	const {
		title,
		visible,
		folder,
		model,
		filter,
		settings,
		selected,
		onClose,
		totalCount,
		fields,
		urls,
		valueColors,
	} = props
	const over2000pins = totalCount > 2000

	const mapContainerRef = useRef<HTMLDivElement>(null)

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyBAFm0Oby3VJX7plAogQWWgu79coqQ_1f0',
	})

	const [map, setMap] = useState<google.maps.Map | null>(null)
	const [activeMarker, setActiveMarker] = useState(null)
	const [ajaxLoading, setAjaxLoading] = useState(true)

	const [width, setWidth] = useState(settings.width)
	const [height, setHeight] = useState(settings.height)
	const [markers, setMarkers] = useState<MarkerData[]>([])
	const [itemIdArray, setItemIdArray] = useState<any>([])
	const [data, setData] = useState<any>([])
	const [colorscount, setColorscount] = useState<any>([])

	const [settingsHash, setSettingsHash] = useState('')
	const [settingsTimeout, setSettingsTimeout] = useState()
	const [center, setCenter] = useState<LatLng>(settings.center)
	const [zoom, setZoom] = useState(settings.zoom)

	const [upperLeft, setUpperLeft] = useState<String | null>(null)
	const [lowerRight, setLowerRight] = useState<String | null>(null)
	const [pinsCount, setPinsCount] = useState(totalCount)

	const [urlHash, setUrlHash] = useState('')
	const [centerMap, setCenterMap] = useState(false)
	const [details, setDetails] = useState<any>([])
	const [detailsId, setDetailsId] = useState(null)
	const [showRow, setShowRow] = useState(false)

	const handleShowCloseButton = () => {
		onClose()
	}

	const closeButtonOptions = {
		onClick: handleShowCloseButton,
		icon: 'remove',
	}

	useEffect(() => {
		if (map && visible && !showRow) {
			let url = `${API_FIBER_URL}/map/${model}?over2000pins=${over2000pins ? 1 : 0}`
			if (filter) {
				url = `${url}&filter=${filter}`
			}
			if (filter && valueColors && Object.entries(valueColors).length > 0) {
				const colorField = Object.keys(valueColors)[0] as any
				const v = Object.entries(valueColors)[0][1] as any
				const colorValue = Object.keys(v) as any
				url = `${url}&colorField=${encodeURIComponent(colorField)}&colorValue=${encodeURIComponent(colorValue)}`
			}
			if (over2000pins) {
				url = `${url}&upperLeft=${upperLeft}&lowerRight=${lowerRight}`
			}
			const hash = md5(url)
			if (urlHash !== hash) {
				if (centerMap && mapContainerRef && mapContainerRef.current) {
					const mapWidth = mapContainerRef.current.offsetWidth
					const mapHeight = mapContainerRef.current.offsetHeight
					url = `${url}&centerMap=1&mapWidth=${mapWidth}&mapHeight=${mapHeight}`
				}
				const cancelTokenSource = axios.CancelToken.source()
				setAjaxLoading(true)
				axios
					.get(url, {
						cancelToken: cancelTokenSource.token,
					})
					.then(response => response.data)
					.then(data => {
						setMarkers(data.pins)
						setPinsCount(data.pinsCount)
						if (map && data.center) {
							map.setCenter(data.center)
						}
						if (map && data.zoom) {
							map.setZoom(data.zoom)
						}
						if (valueColors) {
							setColorscount(data.colorCount)
						}
						setCenterMap(false)
						setUrlHash(hash)
						setAjaxLoading(false)
					})
					.catch(error => {
						if (!axios.isCancel(error)) {
							setAjaxLoading(false)
						}
					})
				return () => {
					if (urlHash !== hash) {
						cancelTokenSource.cancel('Request canceled')
					}
				}
			}
		}
	}, [model, filter, upperLeft, lowerRight, over2000pins, centerMap, map, visible, urlHash, showRow, valueColors])

	const handleBounds = useCallback(() => {
		if (map) {
			const bounds = map.getBounds()
			if (bounds) {
				const newUpperLeft = bounds.getNorthEast().toJSON()
				const newLowerRight = bounds.getSouthWest().toJSON()
				setUpperLeft(JSON.stringify(newUpperLeft))
				setLowerRight(JSON.stringify(newLowerRight))
			}
		}
	}, [map])

	const calculateCenter = () => {
		if (map) {
			const bounds = map.getBounds()
			if (bounds) {
				const newUpperLeft = bounds.getNorthEast().toJSON()
				const newLowerRight = bounds.getSouthWest().toJSON()
				const centerLat = (newUpperLeft.lat + newLowerRight.lat) / 2
				const centerLng = (newUpperLeft.lng + newLowerRight.lng) / 2
				return new window.google.maps.LatLng(centerLat, centerLng)
			}
		}
	}

	useEffect(() => {
		const hash = md5(JSON.stringify(settings))
		setSettingsHash(hash)
	}, [settings])

	useEffect(() => {
		const newSettings = {
			width,
			height,
			center,
			zoom,
		}
		const jsonSettings = JSON.stringify(newSettings)
		const hash = md5(jsonSettings)
		if (settingsHash && hash !== settingsHash) {
			setSettingsHash(hash)
			clearTimeout(settingsTimeout)
			const t = setTimeout(() => {
				axios.post(`${API_FIBER_URL}/map/${model}/settings`, jsonSettings, {
					headers: {
						'Content-Type': 'application/json',
					},
				})
			}, 3000)
			setSettingsTimeout(t as any)
		}
	}, [center, zoom, width, height, settingsTimeout, settingsHash, model])

	useEffect(() => {
		if (detailsId) {
			axios
				.get(`${API_FIBER_URL}/view/row/${detailsId}?folder=${folder}&model=${model}`)
				.then(response => response.data)
				.then(data => {
					setDetails(data.values)
					setShowRow(true)
				})
		}
	}, [folder, model, detailsId])

	useEffect(() => {
		const newIds = markers.map(item => item.id)
		const hasChanges = newIds.some(id => !itemIdArray.includes(id))
		if (hasChanges) {
			setItemIdArray(newIds)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [markers])

	useEffect(() => {
		if (itemIdArray.length < 500) {
			const fetchData = async () => {
				try {
					const response = await axios.get(`${API_FIBER_URL}/view/mapData?folder=${folder}&model=${model}`, {
						params: {
							itemIdArray: itemIdArray.join(','),
						},
					})
					setData(response.data.mapData)
				} catch (error) {
					console.error('Error fetching data:', error)
				}
			}

			if (itemIdArray.length > 0) {
				fetchData()
			}
		} else {
			setItemIdArray([])
		}
	}, [folder, model, itemIdArray])

	const handleLoad = useCallback((map: google.maps.Map) => {
		setMap(map)
		const controlDiv = document.createElement('div')
		const controlButton = document.createElement('button')
		controlButton.style.backgroundColor = '#fff'
		controlButton.style.border = '0px'
		controlButton.style.borderRadius = '3px'
		controlButton.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)'
		controlButton.style.color = 'rgb(25,25,25)'
		controlButton.style.cursor = 'pointer'
		controlButton.style.fontFamily = 'Roboto,Arial,sans-serif'
		controlButton.style.fontSize = '11px'
		controlButton.style.lineHeight = '16px'
		controlButton.style.margin = '4px 4px 0'
		controlButton.style.padding = '2px 4px'
		controlButton.style.textAlign = 'center'
		controlButton.textContent = 'Center'
		controlButton.title = 'Center on filtered rows'
		controlButton.type = 'button'
		controlDiv.appendChild(controlButton)
		controlDiv.addEventListener('click', () => {
			setCenterMap(true)
			setUrlHash('')
		})
		map.controls[google.maps.ControlPosition.TOP_RIGHT].push(controlDiv)
	}, [])

	const handleUnmount = useCallback((map: google.maps.Map) => {
		setMap(null)
	}, [])

	const handleCenterChanged = useCallback(() => {
		if (map) {
			const center = map.getCenter()
			if (center) {
				setCenter(center.toJSON())
			}
		}
	}, [map])

	const handleZoomChanged = useCallback(() => {
		if (map) {
			setZoom(map.getZoom())
		}
	}, [map])

	const handleMouseOver = (key: any) => {
		setActiveMarker(key)
	}

	const handleMouseOut = () => {
		setActiveMarker(null)
	}

	const handleMarkerClick = (id: any) => {
		setDetailsId(id)
	}

	const handleHideDetail = () => {
		setShowRow(false)
		setDetailsId(null)
	}

	const Legend = ({ valueColors }: { valueColors: { [key: string]: { [key: string]: string } } }) => {
		return (
			<div className="legend">
				{Object.entries(valueColors).map(([field, colorMap]) => (
					<div key={field} className="legend-item">
						{Object.entries(colorMap).map(([value, color]) => (
							<div key={value}>
								<div className="legend-item-inner">
									<div className="color-box" style={{ backgroundColor: color }}></div>
									{colorscount ? (
										<span style={{ fontSize: '10px' }}>
											{field}: {value} ({colorscount[value]})
										</span>
									) : (
										<span style={{ fontSize: '10px' }}>
											{field}: {value}
										</span>
									)}
								</div>
							</div>
						))}
					</div>
				))}
			</div>
		)
	}

	const renderContent = () => {
		return isLoaded ? (
			<div ref={mapContainerRef} className="map-container">
				<GoogleMap
					mapContainerStyle={{
						width: '100%',
						height: '97%',
					}}
					center={settings.center}
					zoom={settings.zoom}
					onLoad={handleLoad}
					onUnmount={handleUnmount}
					onCenterChanged={handleCenterChanged}
					onZoomChanged={handleZoomChanged}
					onBoundsChanged={handleBounds}
					options={{
						fullscreenControl: false,
						controlSize: 20,
						clickableIcons: false,
						styles: [
							{
								featureType: 'poi',
								elementType: 'labels',
								stylers: [{ visibility: 'off' }],
							},
							{
								featureType: 'transit',
								elementType: 'labels',
								stylers: [{ visibility: 'off' }],
							},
							{
								featureType: 'road',
								elementType: 'labels.icon',
								stylers: [{ visibility: 'off' }],
							},
						],
					}}
				>
					{urlHash && pinsCount > 2000 && (
						<InfoBox
							options={{
								closeBoxURL: '',
								enableEventPropagation: true,
							}}
							position={calculateCenter()}
						>
							<div className="customClusterer">
								<p>{pinsCount}</p>
							</div>
						</InfoBox>
					)}
					{!ajaxLoading && pinsCount <= 2000 && (
						<MarkerClusterer maxZoom={20} minimumClusterSize={5}>
							{clusterer => (
								<>
									{markers.map((item, key) => {
										const marker: LatLng = {
											lat: parseFloat(item.lat),
											lng: parseFloat(item.lng),
										}
										const customMarkerIcon = {
											url: require('../../assets/images/map-marker-vector.png'),
											scaledSize: new window.google.maps.Size(28, 40),
										}
										const markerIcon =
											activeMarker === key || selected === item.id ? customMarkerIcon : undefined

										const colorMarkerIcon = {
											path: 'M10,2C6.4,2,3.5,4.9,3.5,8.5c0,5.5,6.5,12.5,6.5,12.5S17.5,14,17.5,8.5C17.5,4.9,14.6,2,11,2H10z',
											fillColor: 'red',
											fillOpacity: 1,
											strokeWeight: 1,
											strokeColor: '#ffffff',
											scaledSize: new window.google.maps.Size(28, 50),
											anchor: new window.google.maps.Point(10, 20),
										}

										data.forEach((marker: any) => {
											Object.entries(valueColors).forEach(([field, colorMap]) => {
												Object.entries(colorMap as any).forEach(([value, color]) => {
													if (marker.Kid === item.id && marker[field] === value) {
														colorMarkerIcon.fillColor = color as string
													}
												})
											})
										})

										return (
											<Marker
												key={key}
												position={marker}
												clusterer={clusterer}
												onMouseOver={() => handleMouseOver(key)}
												onMouseOut={handleMouseOut}
												onClick={() => handleMarkerClick(item.id)}
												{...(markerIcon !== undefined
													? { icon: markerIcon }
													: { icon: colorMarkerIcon })}
											>
												{item.label && activeMarker === key && (
													<InfoWindow position={marker}>
														<div>
															<div className="imageContainer">
																{item.photo && <img alt="" src={item.photo} />}
															</div>
															<p>{item.label}</p>
														</div>
													</InfoWindow>
												)}
											</Marker>
										)
									})}
								</>
							)}
						</MarkerClusterer>
					)}
				</GoogleMap>
				{showRow && <Rowdetails fields={fields} values={details} urls={urls} onHide={handleHideDetail} />}
				<LoadPanel position={{ of: '.map-container' }} visible={ajaxLoading} hideOnOutsideClick={false} />
				{valueColors && <Legend valueColors={valueColors} />}
			</div>
		) : (
			<></>
		)
	}

	const handleResize = (e: any) => {
		setWidth(e.width)
		setHeight(e.height)
	}

	const mapTitle = (title: string) => {
		if (!title) {
			title = 'Google map'
		}
		if (`${DEBUG}` === '1') {
			title = `${title} - Center: ${center.lat.toFixed(4)}, ${center.lng.toFixed(4)} - Zoom: ${zoom}`
		}
		return title
	}

	return (
		<Popup
			visible={visible}
			width={width}
			height={height}
			contentRender={renderContent}
			onResizeEnd={handleResize}
			resizeEnabled={true}
			dragEnabled={true}
			shading={false}
			position="right bottom"
			title={mapTitle(title)}
		>
			<ToolbarItem widget="dxButton" location="after" options={closeButtonOptions} />
		</Popup>
	)
}
