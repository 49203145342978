import React, { FC, useState, useEffect, useRef } from 'react'
import { Popup } from 'devextreme-react/popup'
import DataGrid, { Column, HeaderFilter, Selection, Paging } from 'devextreme-react/data-grid'
import DateBox from 'devextreme-react/date-box'
import { LoadIndicator } from 'devextreme-react/load-indicator'
import { TimestampDisplay } from '../../components'
import axios from 'axios'

interface AuditTrailPopupProps {
	visible: boolean
	pKey: any
	dataField: string
	folder: string | null
	model: string | null
	data: string[]
	onHide: any
}

const AuditTrail: FC<AuditTrailPopupProps> = ({ visible, pKey, dataField, folder, model, data, onHide }) => {
	const gridRef = useRef<DataGrid | null>(null)
	const [items, setItems] = useState<any>([])
	const [loading, setLoading] = useState<boolean>(true)
	const [date, setDate] = useState<Date | null>(null)
	const [id, setId] = useState<any>()
	const [date30DaysPrior, setDate30DaysPrior] = useState<Date>(new Date())

	useEffect(() => {
		let id = null
		let url = null
		let composedValues = []
		setLoading(true)

		if (Array.isArray(pKey)) {
			for (let i = 0; i < pKey.length; i++) {
				let value = data[pKey[i]]
				composedValues.push(value)
			}
			setId(composedValues)
			id = btoa(JSON.stringify(composedValues))
		} else {
			id = data[pKey]
			setId(id)
		}

		if (date !== null) {
			const isoDateString = date.toISOString().split('T')[0]
			url = `${encodeURIComponent(id)}/${dataField}/?folder=${folder}&model=${model}&date=${isoDateString}`
			const daysPrior = getDate30DaysPrior(date)
			setDate30DaysPrior(daysPrior)
		} else {
			url = `${encodeURIComponent(id)}/${dataField}/?folder=${folder}&model=${model}`
		}

		axios
			.get(`${API_FIBER_URL}/view/audit/${url}`)
			.then(response => {
				setItems(response.data.results)
			})
			.catch(error => {
				console.error('Error fetching data:', error)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [dataField, date, folder, model, pKey, data])

	const handleDateChange = (value: any) => {
		setDate(value)
	}

	const getDate30DaysPrior = (currentDate: Date) => {
		const priorDate = new Date(currentDate)
		priorDate.setDate(priorDate.getDate() - 31)
		return priorDate
	}

	const renderContent = () => {
		return (
			<div>
				<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
					<div>
						<p style={{ margin: '0 0 10px 0', fontWeight: 'bold', fontSize: 20 }}>Audit Trail</p>
						<span style={{ fontWeight: 'bold' }}>Column: </span>
						<span>{dataField}</span>
						<div style={{ display: 'flex', gap: '1vw' }}>
							<span style={{ fontWeight: 'bold' }}>Primary Key:</span>
							{Array.isArray(pKey) ? (
								pKey.map((key, index) => (
									<div key={index}>
										<span>
											{key}: {Array.isArray(id) ? id[index] : id}
										</span>
									</div>
								))
							) : (
								<div>
									<span>
										{pKey}: {id}
									</span>
								</div>
							)}
						</div>
					</div>
					<div className="datePickerAudit">
						<span>Select a date to retrieve data from 30 days prior</span>
						<div>
							<DateBox
								defaultValue={date}
								inputAttr={{ 'aria-label': 'Date' }}
								type="date"
								value={date ? date : new Date()}
								onValueChange={handleDateChange}
							/>
							<br />
							{date ? (
								<span>
									Date Range: {date.toLocaleDateString()} - {date30DaysPrior.toLocaleDateString()}
								</span>
							) : (
								<span>
									Date Range: {new Date().toLocaleDateString()} -{' '}
									{date30DaysPrior.toLocaleDateString()}
								</span>
							)}
						</div>
					</div>
				</div>
				{loading ? (
					<LoadIndicator id="large-indicator" height={80} width={80} />
				) : (
					<DataGrid
						ref={gridRef}
						dataSource={items}
						rowAlternationEnabled={true}
						allowColumnResizing={true}
						columnResizingMode="widget"
						columnAutoWidth={true}
						showBorders={true}
						width="100%"
					>
						<Paging defaultPageSize={15} defaultPageIndex={0} />
						<Selection mode="single" />
						<Column dataField="old_value" />
						<Column dataField="new_value" />
						<Column
							dataField="time"
							cellRender={({ data }) => {
								return <TimestampDisplay timestamp={data['time']}></TimestampDisplay>
							}}
						/>
						<Column dataField="user_name" />
					</DataGrid>
				)}
				<HeaderFilter visible={true} />
			</div>
		)
	}

	return (
		<Popup
			visible={visible}
			width={'48vw'}
			showTitle={false}
			contentRender={renderContent}
			position="center"
			hideOnOutsideClick={() => {
				return true
			}}
			onHiding={onHide}
		/>
	)
}

export default AuditTrail
