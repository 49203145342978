import React, { useEffect } from 'react'
import { useSharePoint } from './SharePointContext'

const SharePointLoader: React.FC = () => {
	const { setSharePointLogged } = useSharePoint()

	useEffect(() => {
		const img = new Image()
		img.src = 'https://artemisitsgmbh.sharepoint.com/_api/siteiconmanager/getsitelogo'
		img.onload = () => setSharePointLogged(true)
		img.onerror = () => setSharePointLogged(false)
	}, [setSharePointLogged])

	return null // This component doesn't need to render anything
}

export default SharePointLoader
